import { FormikProps } from 'formik';
import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Ui Kit
import Button from 'ui-kit/button/button';
import Link from 'ui-kit/link/link';

// Display Components
import Stepper from 'display-components/stepper/stepper';

// Components
import { AddressVerificationAPIFailureModalContent } from 'components/add-address-form/AddAddressForm';
import { AddressVerificationForm } from 'components/address-verification-modal/AddressVerificationModal.component';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import MembershipRegistrationContactForm from 'components/membership-registration-contact-form/membership-registration-contact-form.component';
import MembershipRegistrationPersonalInformationForm from 'components/membership-registration-personal-information-form/membership-registration-personal-information-form.component';
import MembershipRegistrationShippingForm from 'components/membership-registration-shipping-form/membership-registration-shipping-form.component';
import WorkflowFooterLinks from 'components/workflow-footer-links/workflow-footer-links';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

// State
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { setMembershipFormValues } from 'state/membership-registration/membership-registration.reducer';
import {
    membershipRegistrationGetEthnicitiesRoutine,
    membershipRegistrationGetGendersRoutine,
    membershipRegistrationGetRacesRoutine
} from 'state/membership-registration/membership-registration.routines';
import { membershipRegistrationFormValuesSelector } from 'state/membership-registration/membership-registration.selectors';
import { AddressParts } from 'state/usps/usps.reducers';

// Schemas
import { CREATE_BIRDI_ACCOUNT_PERSONAL_DETAILS_SCHEMA } from 'schema/create-birdi-account-personal-info.schema';
import { CREATE_BIRDI_ACCOUNT_SHIPPING_INFO_SCHEMA } from 'schema/create-birdi-account-shipping-information.schema';
import { MEMBERSHIP_REGISTRATE_CONTACT_SCHEMA } from 'schema/membership/registrate-contact-form.schema';

// Types
import { MembershipRegistrationFormPersonalInfoProps } from 'types/membership';

// Utils
import { AddressValidateResponse } from 'util/usps';

// Hooks
import { useAddressVerification } from 'hooks/useAddressVerification';
import useMembershipRegistrationInit from 'hooks/useMembershipRegistrationInit';

import './index.style.scss';

/**
 * Renders the Profile Setup page.
 *
 * @param {Object} props - The props object.
 * @param {any} props.location - The location object.
 * @param {GatsbyTypes.ProfileSetupPageDataQuery} props.data - The data object.
 * @return {ReactElement} The rendered Profile Setup page.
 */
const ProfileSetupPage = ({
    location,
    data
}: {
    location: any;
    data: GatsbyTypes.ProfileSetupPageDataQuery;
}): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { verifyAddress } = useAddressVerification();
    const formRefPersonalInformation = useRef<FormikProps<MembershipRegistrationFormPersonalInfoProps>>(null);
    const formRefContact = useRef<FormikProps<MembershipRegistrationFormPersonalInfoProps>>(null);
    const formRefShipping = useRef<FormikProps<MembershipRegistrationFormPersonalInfoProps>>(null);
    const [isInitialized, setIsInitialized] = useState(false);
    const formValues = useSelector(membershipRegistrationFormValuesSelector);
    const { onInit } = useMembershipRegistrationInit();

    const initialContactValues = {
        phoneNumber: formValues?.phoneNumber || undefined,
        username: formValues?.email || undefined,
        password: formValues?.password || undefined,
        passwordConfirm: formValues?.passwordConfirm || undefined
    };

    const resetContactValues = {
        phoneNumber: formValues?.phoneNumber,
        username: formValues?.email,
        password: formValues?.password,
        passwordConfirm: formValues?.passwordConfirm
    };

    // Footer links selector
    const workflowFooterLinks =
        data.allBlockContentRegistrationFlowFooterLinks?.edges?.[0]?.node?.field_registration_footer_link ?? [];

    useEffect(() => {
        dispatch(membershipRegistrationGetGendersRoutine.trigger());
        dispatch(membershipRegistrationGetRacesRoutine.trigger());
        dispatch(membershipRegistrationGetEthnicitiesRoutine.trigger());
    }, [dispatch]);

    useEffect(() => {
        const hasValidUserInfo = onInit();
        if (!hasValidUserInfo) {
            navigate('/get-started/membership');
        } else {
            // tracking pageview
            _cio.page(window.location.href);
        }

        setIsInitialized(true);
    }, [formValues, onInit]);

    const verifyMemberAddress = () => {
        // DRX-2982: As users may select a recommended address, we need to validate
        // the receiving address before proceeding to the next page.

        // Address needs to be validated prior to dispatching `accountRegisterRoutine`
        const address: AddressParts = {
            street1: formRefShipping.current?.values ? formRefShipping.current.values.address1 : '',
            street2: formRefShipping.current?.values ? formRefShipping.current.values.address2 : '',
            city: formValues.city?.trim() ?? '',
            state: formValues.state ? formValues.state : '',
            zip: formValues.zipcode ? formValues.zipcode : '',
            zip4: ''
        };

        //DRX-3230: Avoid unnecessary API calls by validating the forms schemas
        const isPersonalInfoValid = CREATE_BIRDI_ACCOUNT_PERSONAL_DETAILS_SCHEMA.isValidSync(
            formRefPersonalInformation.current?.values
        );
        const isContactInfoValid = MEMBERSHIP_REGISTRATE_CONTACT_SCHEMA('US').isValidSync(
            formRefContact.current?.values
        );
        const isShippingAddressValid = CREATE_BIRDI_ACCOUNT_SHIPPING_INFO_SCHEMA.isValidSync(
            formRefShipping.current?.values
        );

        if (!isPersonalInfoValid || !isContactInfoValid || !isShippingAddressValid) {
            formRefPersonalInformation.current?.validateForm();
            formRefContact.current?.validateForm();
            formRefShipping.current?.validateForm();
            return;
        }

        verifyAddress({
            address,
            onSuccess: (validationResponse: AddressValidateResponse) => {
                if (validationResponse.responseCode === 'suggested') {
                    dispatch(
                        openModal({
                            showClose: false,
                            bodyContent: (
                                <AddressVerificationForm
                                    originalAddress={validationResponse.currentAddress!}
                                    correctedAddress={validationResponse.updatedAddress!}
                                    onSuccess={onVerificationAddressChoice}
                                    onClose={onCloseValidationChoice}
                                    className="membership-registration"
                                />
                            )
                        })
                    );
                }

                // customer.io: track with first and last name
                _cio.identify({
                    id: formValues.email,
                    first_name: formRefPersonalInformation.current?.values.firstName,
                    last_name: formRefPersonalInformation.current?.values.lastName,
                    // Strongly recommended when you first identify someone
                    created_at: Math.floor(Date.now() / 1000)
                });
            },
            onFailure: (validationResponse: AddressValidateResponse) => {
                dispatch(
                    openModal({
                        showClose: true,
                        className: 'prescription-modal',
                        bodyContent: <AddressVerificationAPIFailureModalContent translation={t} />,
                        ctas: [
                            {
                                label: t('modals.healthConditions.submit'),
                                variant: 'primary',
                                onClick: () => {
                                    dispatch(closeModal({}));
                                },
                                dataGALocation: 'AddressVerificationError'
                            }
                        ]
                    })
                );
            }
        });
    };

    const onVerificationAddressChoice = (address: AddressParts) => {
        if (address) {
            const valuesWithChosenAddress = {
                ...formValues,
                ...address,
                zipcode: (address.zipcode as string).length > 5 ? (address.zipcode as string).slice(0, 5) : address.zip,
                zip4: (address.zipcode as string).length > 5 ? (address.zipcode as string).slice(-4) : ''
            };
            onCloseValidationChoice();
            handleJoinSubmit(valuesWithChosenAddress);
        }
    };

    const onCloseValidationChoice = () => {
        dispatch(closeModal({}));
    };

    const handleJoinSubmit = (verifiedAddress: AddressParts) => {
        const formData = {
            ...verifiedAddress,
            ...formRefPersonalInformation.current?.values,
            ...formRefContact.current?.values,
            ...formRefShipping.current?.values
        };

        if (formRefPersonalInformation.current) {
            formRefPersonalInformation.current.validateForm();
            formRefPersonalInformation.current.submitForm();
        }
        if (formRefContact.current) {
            formRefContact.current.validateForm();
            formRefContact.current.submitForm();
        }
        if (formRefShipping.current) {
            formRefShipping.current.validateForm();
            formRefShipping.current.submitForm();
        }

        Promise.all([
            formRefShipping.current?.submitForm(),
            formRefContact.current?.submitForm(),
            formRefPersonalInformation.current?.submitForm()
        ]).then(() => {
            if (
                formRefShipping.current?.isValid &&
                formRefContact.current?.isValid &&
                formRefPersonalInformation.current?.isValid
            ) {
                dispatch(setMembershipFormValues(formData));
                navigate('/get-started/select-plan');
            }
        });
    };

    return (
        <WorkflowLayout
            backgroundImage={data.backgroundImage}
            useRoundedCorners={false}
            metaData={{ nodeTitle: t('membership.profileSetup.eyebrowText') }}
            anonymousOnly={true}
        >
            <WorkflowLayoutFormWrapper
                className="membership-get-started-personal-details"
                currentFlow="membership-registration"
                eyebrowText={t('membership.profileSetup.eyebrowText')}
                title={t('membership.profileSetup.profileTitle')}
            >
                <MembershipRegistrationPersonalInformationForm
                    initialValues={{
                        firstName: formValues?.firstName,
                        lastName: formValues?.lastName,
                        dateOfBirth: formValues?.dateOfBirth,
                        dobMonth: formValues?.dobMonth,
                        dobDay: formValues?.dobDay,
                        dobYear: formValues?.dobYear,
                        gender: formValues?.gender,
                        race: formValues?.race,
                        ethnicity: formValues?.ethnicity,
                        careProvider: formValues?.careProvider
                    }}
                    state={location.state}
                    onSubmit={() => {
                        // empty
                    }}
                    ref={formRefPersonalInformation}
                    validationSchema={CREATE_BIRDI_ACCOUNT_PERSONAL_DETAILS_SCHEMA}
                />

                <div className="membership-profile-setup-mt-5">
                    <MembershipRegistrationContactForm
                        initialValues={isInitialized ? resetContactValues : initialContactValues}
                        onSubmit={() => {
                            // empty
                        }}
                        ref={formRefContact}
                        isUsernameReadOnly={true}
                    />
                </div>

                <div className="membership-profile-setup-mt-5">
                    <MembershipRegistrationShippingForm
                        initialValues={{
                            address1: formValues?.address1,
                            address2: formValues?.address2,
                            city: formValues.city,
                            state: formValues.state,
                            zipcode: formValues.zipcode
                        }}
                        onSubmit={() => {
                            // empty
                        }}
                        ref={formRefShipping}
                    />
                </div>

                <div className="membership-profile-setup__sections__cta">
                    <Button
                        async
                        label={t('membership.profileSetup.ctas.continue')}
                        type="submit"
                        className="btn-personal-details-submit"
                        variant="primary"
                        onClick={verifyMemberAddress}
                    />
                    <Link
                        to={'/get-started/gov-insurance'}
                        label={t('membership.profileSetup.ctas.backLink')}
                        dataGALocation={'membership'}
                        className="btn-back-link"
                        variant="subtle"
                        external={false}
                    />
                </div>

                <Stepper activeStep={2} steps={['1', '2', '3', '4']} />
            </WorkflowLayoutFormWrapper>

            {/* Workflow links */}
            <WorkflowFooterLinks links={workflowFooterLinks as any} locationGAflow="membership personal details" />
        </WorkflowLayout>
    );
};

export default ProfileSetupPage;

export const query = graphql`
    query ProfileSetupPageData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        backgroundImage: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
        allBlockContentRegistrationFlowFooterLinks(
            filter: { field_registration_flow_types: { eq: "membership_flow" } }
        ) {
            edges {
                node {
                    field_registration_footer_link {
                        title
                        uri
                    }
                }
            }
        }
    }
`;
